import axios from 'axios';

let projects = null;

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Function to fetch the projects.json file
const fetchProjectsJson = async () => {
    if (!projects && process.env.REACT_APP_BUILD_FOR_ARCHIVAL) {
        try {
            const response = await axios.get('/api/v1/projects.json');
            projects = response.data;
        } catch (error) {
            console.error('Error fetching projects.json:', error);
        }
    }
};

// Add a request interceptor
instance.interceptors.request.use(async (config) => {
    await fetchProjectsJson(); // Ensure projects.json is fetched

    if (process.env.REACT_APP_BUILD_FOR_ARCHIVAL === 'true') {
        config.baseURL = window.location.origin + '/api/v1';
    }

    const url = config.url;

    // Check if the URL matches any entry in the projects.json file
    if (projects && projects[url]) {
        // If a match is found, return the data from the projects.json file
        return {
            ...config,
            adapter: () => {
                return new Promise((resolve) => {
                    resolve({
                        data: projects[url],
                        status: 200,
                        statusText: 'OK',
                        headers: {},
                        config,
                        request: {},
                    });
                });
            },
        };
    }

    // If BUILD_FOR_ARCHIVAL is true, append .json to non-project URLs
    if (process.env.REACT_APP_BUILD_FOR_ARCHIVAL === 'true' && !url.startsWith('/projects/')) {
        // Remove trailing slash if present
        if (url.endsWith('/')) {
            config.url = url.slice(0, -1);
        }
        config.url = `${config.url}.json`;
    }

    // If no match is found, proceed with the actual request
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;
